import { Image as ImageType } from '@type-declarations/media';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import styles from './Figure.module.scss';

interface Props {
  image: ImageType;
  alt?: string;
  sizes?: string;
  caption?: string;
  className?: string | false;
  imageClassName?: string | false;
}

function Figure({
  image,
  alt = '',
  sizes,
  caption,
  className,
  imageClassName,
}: Props) {
  return (
    <figure className={clsx(styles.figure, className)}>
      <Image
        src={image.url}
        placeholder="blur"
        blurDataURL={image.placeholder}
        alt={alt}
        sizes={sizes}
        objectFit="cover"
        objectPosition="center"
        layout="fill"
        className={clsx(styles.image, imageClassName)}
      />
      {caption && <figcaption className={styles.caption}>{caption}</figcaption>}
    </figure>
  );
}

export default Figure;
