import NewsletterSignup from '@molecules/Newsletter/NewsletterSignup';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { NewsletterSection as Props } from '@type-declarations/sections';

import styles from './NewsletterSection.module.scss';

function NewsletterSection({ title, text, backgroundColor }: Props) {
  return (
    <Section backgroundColor={backgroundColor.color}>
      <div className={styles.wrapper}>
        <SectionContent className={styles.content} title={title} text={text} />
        <div className={styles.formContent}>
          <NewsletterSignup />
        </div>
      </div>
    </Section>
  );
}

export default NewsletterSection;
