import Figure from '@atoms/Figure/Figure';
import TextMediaWrapper from '@molecules/TextMediaWrapper/TextMediaWrapper';
import { TextImageSection as Props } from '@type-declarations/sections';

import styles from './TextImageSection.module.scss';

function TextImageSection({
  title,
  subtitle,
  text,
  cta,
  backgroundColor,
  textLeft,
  image,
  imageAlt,
  modifier,
}: Props) {
  return (
    <TextMediaWrapper
      title={title}
      subtitle={subtitle}
      text={text}
      cta={cta}
      textLeft={textLeft}
      backgroundColor={backgroundColor.color}
      modifier={modifier === 'inStore' ? 'inStore' : undefined}
    >
      <Figure
        image={image}
        alt={imageAlt}
        sizes="(min-width: 1024px) 608px, (min-width: 1280px) 640px"
        className={styles.figure}
        imageClassName={styles.image}
      />
    </TextMediaWrapper>
  );
}

export default TextImageSection;
