import Figure from '@atoms/Figure/Figure';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { ImageSection as Props } from '@type-declarations/sections';
import clsx from 'clsx';

import styles from './ImageSection.module.scss';

function ImageSection({
  title,
  subtitle,
  text,
  cta,
  image,
  imageAlt,
  backgroundColor,
  fullWidth,
  modifier,
}: Props) {
  return (
    <Section backgroundColor={backgroundColor.color}>
      <SectionContent
        title={title}
        subtitle={subtitle}
        text={text}
        cta={cta}
        className={styles.content}
        centered
        modifier={modifier === 'inStore' ? modifier : undefined}
      />

      <Figure
        image={image}
        alt={imageAlt}
        sizes={
          fullWidth
            ? '(min-width: 1024px) 1231px, (min-width: 1280px) 1296px'
            : '(min-width: 1024px) 608px, (min-width: 1280px) 640px'
        }
        className={clsx(styles.imageWrapper, fullWidth && styles.fullWidth)}
        imageClassName={styles.image}
      />
    </Section>
  );
}

export default ImageSection;
