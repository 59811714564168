import { Image as ImageType } from '@type-declarations/media';
import Image from 'next/legacy/image';

import styles from './ImageSliderItem.module.scss';

export default function ImageSliderItem({
  url,
  caption,
  placeholder,
}: ImageType) {
  return (
    <div className={styles.imageWrapper}>
      <Image
        src={url}
        placeholder="blur"
        blurDataURL={placeholder}
        alt={caption || ''}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        itemProp="image"
        className={styles.image}
      />
    </div>
  );
}
